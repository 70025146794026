import axios from "axios";

export default axios.create({
  baseURL:
    "https://eu-west-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/application-internships-udpcd/service/jobs/incoming_webhook/",
  // "https://eu-west-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/restaurant-reviews-zuehr/service/restaurants/incoming_webhook/",
  // "https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/restaurant-reviews-evuay/service/restaurants/incoming_webhook/",
  headers: {
    "Content-type": "application/json",
  },
});
