import { useState } from "react";
// import { sanitize } from '../../../utils/miscellaneous';
// import Loading from '../../loading';

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Please enter a valid email address");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return message;
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? formattedMessage : null;
  };

  return (
    <div>
      <div className="subscribe-email-container">
        {/* <h3 className="subscribe-emvail-item">Subscribe to newsletter</h3> */}
        <div className="subscribe-email-item-div">
          <div className="subscribe-email-item">
            {/* <div className="subscribe-email-item"> */}
            <input
              onChange={(event) => setEmail(event?.target?.value ?? "")}
              type="email"
              autoComplete="nope"
              placeholder="     Enter your email"
              className="input-newsletter"
              onKeyUp={(event) => handleInputKeyEvent(event)}
            />
            {/* </div> */}
            {/* <div className="button-wrap wp-block-button"> */}
            <button
              className="btn--submitNewsletter"
              onClick={handleFormSubmit}
            >
              Subscribe
            </button>
            {/* </div> */}
          </div>
        </div>
        <div className="text-newsletter-correct">
          {/* <div className="min-h-42px"> */}
          {"sending" === status ? (message = "Sending...") : null}
          {"error" === status || error ? (
            <div
              className="text-red"
              dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
            />
          ) : null}
          {"success" === status && "error" !== status && !error && (
            <div
              className="text-green"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {/* </div> */}
          <div className="subscribe-email-text">
            Best internships in your inbox. Every week. Subscribe:
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterForm;
