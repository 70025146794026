import React, { useState, useEffect } from "react";
import Pagination from "./Pagination";
import JobDataService from "./services/job";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import "./Cards.css";
import CardItem from "./CardItem";
import "./HeroSection.css";
import "./Button.css";
import Tesla from "./images/tesla_black_long.png";
import PayPal from "./images/paypal_black.png";
import Snap from "./images/snap_inc_text.png";
import Disney from "./images/Disney.png";
import Intel from "./images/Intel_black.png";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "react-toggle/style.css";
import NewsletterForm from "./NewsletterForm";

const JobsList = (props) => {
  const [click, setClick] = useState(true);
  const handleClick = () => setClick(!click);

  const [filterButton, pressedFilterButton] = useState({ find: "companyName" });
  // const handleFilter = () => setPages()

  const [isOpen, setIsOpen] = useState(false);

  // const totalPages = props.totalPages || 15;
  const pageNumber = props.pageNumber || 1;
  // const pageNumber = props.params.pageNumber || 1;

  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [status, setStatus] = useState(true);

  const [page, setPage] = useState(pageNumber);
  // const [page, setPage] = useState(localStorage.getItem("page"));
  const [pages, setPages] = useState(1);

  const url =
    "https://vvatch.us18.list-manage.com/subscribe/post?u=070d797cbb2c98691133de6d6&amp;id=71f6229e14";

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        retrieveJobs();
        retrievePages();
        // find("Remote");
        pages = retrievePages();
        // const res = await fetch(`/api/v1/posts?page=${page}`);

        // const { data, pages: totalPages } = await res.json();
        // setPages(totalPages);
        setJobs(jobs);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError("Some error occured");
      }
    };
    fetchJobs();
  }, [page]);

  const retrievePages = () => {
    JobDataService.getPage(pages)
      .then((response) => {
        // console.log(response.data);
        setPages(response.data.totalPages);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveJobs = () => {
    JobDataService.getAll(page)
      .then((response) => {
        console.log(response.data);
        setJobs(response.data.jobs);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const refreshList = () => {
    retrieveJobs();
  };
  // const { pages = 15 } = () => {
  //   // pressedFilterButton(true);
  //   JobDataService.getPage(15)
  //     .then((response) => {
  //       console.log(response.data);
  //       setJobs(response.data.jobs);
  //       setPages(15)
  //       setPage(1)
  //       setPages(7)
  //     })

  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  const find = (query, by) => {
    // pressedFilterButton(true);
    JobDataService.find(query, by)
      .then((response) => {
        console.log(response.data);
        setJobs(response.data.jobs);
        // setPage(response.data.total_results)
        setPages(response.data.totalPages);
        // setPages(10)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findAll = (query) => {
    JobDataService.find(query)
      .then((response) => {
        console.log(response.data);
        setJobs(response.data.jobs);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const findSomeStuffAll = (query, page) => {
    JobDataService.find(query, page)
      .then((response) => {
        console.log(response.data);
        setJobs(response.data.jobs);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <div>
        <div className="hero-container">
          {/* <button className="btn--post" to="/products">
            Post a Job */}
          <Link to="/hire" className="btn--post">
            Post a job
          </Link>
          {/* </button> */}
          <h1>
            Find your next <br /> <div class="headline-placeholder"></div>
          </h1>
          {/* <h1>
            Find your next <br /> internship{" "}
          </h1> */}
          <p>The web's largest aggregator of Internships and Graduate Jobs.</p>
          <div>
            <div className="allButtons-div">
              <div className="buttons-div">
                <button
                  className="btn--position"
                  onClick={() => find("Finance")}
                  // onClick={() => setIsOpen(!isOpen)}
                >
                  🤵‍♀️ Finance
                </button>

                <button
                  className="btn--position"
                  onClick={() => find("Marketing")}
                >
                  👩‍🎨 Marketing
                </button>
                <button className="btn--position" onClick={() => find("Sales")}>
                  👨‍💼 Sales
                </button>

                <button
                  className="btn--position"
                  onClick={() => find("Operations")}
                >
                  🥷 Operations
                </button>
                <button
                  className="btn--position"
                  // onChange={this.state.}
                  onClick={() => find("Engineering")}
                >
                  👨‍💻 Software Development
                </button>
              </div>
              <div className="buttons-div-lower">
                <button
                  className="btn--location"
                  onClick={() => find("England")}
                >
                  🏙 United Kingdom
                </button>
                <button
                  className="btn--location"
                  onClick={() => find("Remote")}
                >
                  🌎 Remote
                </button>
              </div>
            </div>
          </div>
          <div className="align-center">
            <div>
              <div className="employers-text">
                Connect with the world's best employers
              </div>
            </div>
            <div className="column">
              <div className="row">
                {/* <div className="column img"> */}
                <div className="img-PayPal">
                  <img src={PayPal} />
                </div>
                <div className="img-Tesla">
                  <img src={Tesla} />
                </div>
                <div className="img-Intel">
                  <img src={Intel} />
                </div>
                <div className="img-Snap">
                  <img src={Snap} />
                </div>
                <div className="img-Disney">
                  <img src={Disney} />
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="jobs__item">
        {/* {restaurants.map((restaurant) => { */}
        {jobs.map((job) => {
          return (
            <div>
              <div>
                <div className="jobs__item__wrap">
                  <CardItem
                    src={job.companyImage}
                    companyName={job.companyName}
                    jobDescription={job.jobDescription}
                    jobPosition={job.jobPosition}
                    cuisine={job.cuisine}
                    location={job.location}
                    datePosted={job.datePosted}
                    applyLink={job.applyLink}
                    featured={job.featured}
                    // jobSalary={job.jobSalary}
                  />
                </div>
              </div>

              <div className="subscribe-email-container">
                <div className="subscribe-email-item-div">
                  <div className="subscribe-email-item">
                    {/* <NewsletterForm>asd</NewsletterForm> */}
                    <MailchimpSubscribe
                      url={url}
                      render={(props) => {
                        const { subscribe, status, message } = props || {};
                        return (
                          <NewsletterForm
                            status={status}
                            message={message}
                            onValidated={(formData) => subscribe(formData)}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <Pagination page={page} pages={pages} changePage={setPage} />
        {/* <Pagination page={page} pages={totalPages} changePage={setPage} /> */}
      </div>
    </div>
  );
};

export default JobsList;

