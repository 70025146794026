import React, { useEffect } from "react";
import Modal from "react-modal";
import Navbar from "./Navbar";
import "./App.css";
import Home from "./Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Services from './components/pages/Services';
import Hire from "./Hire";
// import SignUp from './components/pages/SignUp';
import ReactGA from "react-ga";
import { withRouter } from 'react-router-dom';
ReactGA.initialize("UA-201249494-1");

function App() {
  
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    console.log(window.location.pathname);
  });
  // const TRACKING_ID = "G-C3RCQ11TEB"; // YOUR_OWN_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);

  
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          {/* <Route path='/services' component={Services} /> */}
          <Route path="/hire" component={Hire} />
          {/* <Route path='/sign-up' component={SignUp} /> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
