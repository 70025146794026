import React from "react";
import "./App.css";
import Cards from "./Cards";
import HeroSection from "./HeroSection";
import Footer from "./Footer";
import RestaurantsList from "./jobs-list";
import { Navbar } from "react-bootstrap";
function Home() {
  return (
    <>
      {/* <Navbar /> */}
      {/* <HeroSection /> */}
      <RestaurantsList />
      <Footer />
    </>
  );
}

export default Home;
