import React from "react";
import "./App.css";
import { Widget } from "@typeform/embed-react";
import { ReactTypeformEmbed } from "react-typeform-embed";

const Hire = () => {
  return (
    <div>
      <div className="hire">
        <ReactTypeformEmbed url="https://hp3azlz08vx.typeform.com/to/irI58XFd" />
      </div>
    </div>
  );
};
export default Hire;
