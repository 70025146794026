import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Modal from "react-modal";
import JobDataService from "./services/job";
import JobsList from "./jobs-list";
import "./Details.css";
import "./Cards.css";

// Modal.setAppElement("#root");

function CardItem(props) {
  const [jobs, setJobs] = useState([]);

  const [click, setClick] = useState(true);
  const [applyLinkClick, setapplyLinkClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  // $('.img-featured:empty').hide();

  if (props.featured === "") {
  }

  return (
    <>
      <div>
        <div>
          <li className="cards__item">
            <Link
              className="cards__item__link"
              to={props.path}
              onClick={handleClick}
            >
              {/* <figure
              className="img-companyName"
              data-category={props.companyName}
            > */}
              <figure
                className="img-location"
                data-location={props.location}
              ></figure>
              <figure
                className="img-datePosted"
                data-dateposted={props.datePosted}
              ></figure>

              <figure
                className="img-jobPosition"
                data-jobposition={props.jobPosition}
              ></figure>

              <figure
                className="img-companyName"
                data-companyname={props.companyName}
              >
                {/* <figure
                className="img-applyLink"
                data-applyLink={props.applyLink}
              > */}
                {/* <figure className="img-applyLink" data-applyLink={props.applyLink}> */}
                <div
                  className="img-jobSalary"
                  data-jobsalary={props.jobSalary}
                ></div>

                <div>
                  <figure
                    className={ props.featured ? "img-featured" : "none"}
                    // data-featured={props.featured ? "Featured" : ""}
                    data-featured={props.featured ? "Featured" : ""}
                  ></figure>
                </div>

                <img
                  className="img-companyImage"
                  alt="Logo Image"
                  src={props.src}
                />
              </figure>
              {/* <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div> */}
            </Link>
          </li>
        </div>

        <div>
          <div className="details__container">
            <div className="details__wrapper" onClick={handleClick}>
              <div className={click ? "moreText" : "details__wrapper"}>
                {/* <div className="jobs__item__wrap"> */}
                <div className="modal-text">
                  {/* <img
                      className="modal-image"
                      alt="Restaurant Image"
                      src={props.src}
                    /> */}
                  <div>
                    {/* <div className="modal-text-inside">
                        {props.companyName}
                      </div>
                      <div className="modal-text-inside">
                        {" "}
                        👯‍♀️ {props.jobPosition}
                      </div>
                      <div className="modal-text-inside">
                        {" "}
                        🏙 {props.location}
                      </div> */}
                    {/* <div className="modal-text-inside">{props.jobSalary}</div> */}
                    {/* <div className="modal-text-inside">
                        {" "}
                        🕦 {props.datePosted}
                      </div> */}

                    {/* <div className="modal-text-inside"> 🪧 {props.applyLink} </div> */}
                    {/* <button type="button">Show me on the map!</button> */}

                    <div className="job-description">
                      <div>
                        {/* {" "} */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props.jobDescription,
                          }}
                        />
                        {/* {props.jobDescription} */}
                      </div>
                    </div>
                    <div className="applyContainer">
                      <a
                        className="btn--applyLink"
                        target="_blank"
                        href={props.applyLink}
                      >
                        Apply now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* </Modal> */}
    </>
  );
}
export default CardItem;
