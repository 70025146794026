import http from "../http-common";

class JobDataService {
  getAll(page) {
    return http.get(`jobs?page=${page - 1}`);
  }

  getPage(totalPages) {
    return http.get(`jobs?pagenum=${totalPages}, data)`);
  }

  get(id) {
    return http.get(`/job?id=${id}`);
  }

  find(query, by = "companyName") {
    return http.get(`jobs?${by}=${query}`);
  }



  // findTwo(query, page) {
  //   return http.get(`jobs?${by}=${query}`);
  // }
  // find(query, by = "companyName", page=1) {
  //   return http.get(`jobs?${by}=${query}&page=${page}`);
  // }

  // createReview(data) {
  //   return http.post("/review-new", data);
  // }

  // updateReview(data) {
  //   return http.put("/review-edit", data);
  // }

  // deleteReview(id, userId) {
  //   return http.delete(`/review-delete?id=${id}`, {data:{user_id: userId}});
  // }

  getCuisines(id) {
    return http.get(`/cuisines`);
  }
}

export default new JobDataService();
